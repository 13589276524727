import dynamic from 'next/dynamic'
import React, { useState, useEffect, useRef } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { legal, about } from './footer'
import { useOnClickOutside } from '../utils/hooks/click-outside'
import { useSession } from '../providers/session'
import { useBasket } from '../providers/basket'
import { trackAction } from '../utils/analytics'

const HeaderSearch = dynamic(() => import('./organisms/header-search'))
const CLLogo = dynamic(() => import('../assets/logo.svg'))
const Face = dynamic(() => import('../assets/face-treatment.svg'))
const Body = dynamic(() => import('../assets/body.svg'))
const Hair = dynamic(() => import('../assets/hair-removal.svg'))
const Makeup = dynamic(() => import('../assets/makeup.svg'))
const Lips = dynamic(() => import('../assets/lips.svg'))
const Calendar = dynamic(() => import('../assets/calendar.svg'))
const Basket = dynamic(() => import('../assets/basket.svg'))
const Phone = dynamic(() => import('../assets/phone.svg'))
const WeightLoss = dynamic(() => import('../assets/weight-loss.svg'))
const Cleansing = dynamic(() => import('../assets/cleansing.svg'))
const Patient = dynamic(() => import('../assets/patient.svg'))
// const Drip = dynamic(() => import('../assets/drip.svg'))
const Lift = dynamic(() => import('../assets/lift.svg'))

export default function Header() {
  const router = useRouter()
  const servicesRef = useRef(null)
  const moreRef = useRef(null)
  const mobileRef = useRef(null)
  const session = useSession()
  const basket = useBasket()
  const [state, setState] = useState({
    services: null,
    more: null,
    menu: null,
    search: false,
  })

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      setState({
        services: null,
        more: null,
        menu: null,
        search: false,
      })
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  const close = () =>
    setState({
      services: null,
      more: null,
      menu: null,
      search: false,
    })

  useOnClickOutside(servicesRef, () => {
    if (state.services) {
      close()
    }
  })

  useOnClickOutside(moreRef, () => {
    if (state.more) {
      close()
    }
  })

  useOnClickOutside(mobileRef, () => {
    if (state.menu) {
      close()
    }
  })

  const totalItems = basket.items.services.length + basket.items.products.length

  return (
    <div id="header" className="relative bg-white z-20">
      <div className="relative z-30 shadow">
        <div className="max-w-[1380px] mx-auto flex justify-between items-center px-4 py-5 sm:px-6 sm:py-3 lg:px-8 md:justify-start md:space-x-6">
          <div>
            <Link href="/" passHref className="flex">
              <span className="sr-only">Soluna London</span>
              {/* @ts-ignore */}
              <CLLogo className="w-40" />
            </Link>
          </div>
          <div className="-mr-2 -my-2 flex space-x-5 md:hidden">
            <div className="flex items-center space-x-6">
              <div className="-pt-1">
                {/* <Link href="/booking/" passHref>
                  <a
                    title="Make a booking"
                    className="text-gray-400 hover:text-gray-900 outline-none focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 relative"
                  >
                    {totalItems > 0 && (
                      <span className="absolute top-0 left-3 h-6 w-6 rounded-full ring-2 text-white ring-white bg-brand-400 z-0 font-serif font-bold text-sm flex items-center justify-center -mt-3">
                        {totalItems}
                      </span>
                    )}
                    <Basket className="w-6 h-6" fill="currentColor" />
                  </a>
                </Link>
                 */}
                <button
                  type="button"
                  onClick={() => basket.setOpen(!basket.open)}
                  className="text-gray-400 lg:text-gray-600 hover:text-gray-900 outline-none focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 relative"
                >
                  {totalItems > 0 && (
                    <span className="absolute top-0 left-3 h-6 w-6 rounded-full ring-2 text-white ring-white bg-brand-600 z-0 font-serif font-bold text-sm flex items-center justify-center -mt-3">
                      {totalItems}
                    </span>
                  )}
                  {/* @ts-ignore */}
                  <Basket className="w-6 h-6" fill="currentColor" />
                </button>
              </div>
              <HeaderSearch
                show={state.search}
                close={() => setState({ ...state, search: false })}
              />
              <button
                type="button"
                title="Search Soluna London"
                onClick={() => setState({ ...state, search: !state.search })}
                className="text-gray-400 hover:text-gray-900 outline-none focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                <svg
                  className="w-6 h-6"
                  xmlns="https://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
              <Link
                href={session.profile ? '/account/' : '/sign-in/'}
                passHref
                title="Your Soluna London account"
                className="text-gray-400 hover:text-gray-900 outline-none focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
              >
                <svg
                  className="w-6 h-6"
                  xmlns="https://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </Link>
            </div>
            <button
              onClick={() =>
                setState({
                  more: null,
                  services: null,
                  menu: !state.menu,
                  search: false,
                })
              }
              type="button"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500"
            >
              <span className="sr-only">Open menu</span>
              <svg
                className="h-6 w-6"
                xmlns="https://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <nav className="flex space-x-4 lg:space-x-10">
              <div className="relative">
                <button
                  onClick={() =>
                    setState({
                      more: null,
                      menu: null,
                      services: !state.services,
                      search: false,
                    })
                  }
                  type="button"
                  className={`group bg-white rounded-md inline-flex items-center text-lg font-medium hover:text-gray-900 focus:outline-none font-serif focus:ring-2 focus:ring-offset-2 focus:ring-brand-500  font-medium ${
                    state.services ? 'text-gray-900' : 'text-gray-600'
                  }`}
                >
                  <span>Treatments</span>
                  <svg
                    className={`ml-2 h-5 w-5 group-hover:text-gray-600 ${
                      state.services ? 'text-gray-600' : 'text-gray-400'
                    }`}
                    xmlns="https://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <Link
                href="/laser-hair-removal/"
                passHref
                className="text-lg font-serif text-gray-600 hover:text-gray-900 font-medium"
              >
                Laser Hair Removal
              </Link>
              <Link
                href="/treatments/face/"
                passHref
                className="text-lg font-serif text-gray-600 hover:text-gray-900 font-medium"
              >
                Facials
              </Link>
              <Link
                href="/packages/"
                passHref
                className="text-lg font-serif text-gray-600 hover:text-gray-900 font-medium"
              >
                Packages
              </Link>
              {/* <Link
                href="/conditions/"
                passHref
                className="text-lg font-serif text-gray-600 hover:text-gray-900 font-medium"
              >
                Conditions
              </Link> */}
              {/* <Link
                href="/products/"
                passHref
                className="text-lg font-serif text-gray-600 hover:text-gray-900 font-medium"
              >
                Products
              </Link> */}
              <div className="relative">
                <button
                  onClick={() =>
                    setState({
                      more: !state.more,
                      menu: null,
                      services: null,
                      search: false,
                    })
                  }
                  type="button"
                  className={`group bg-white rounded-md inline-flex items-center text-lg font-serif hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500  font-medium ${
                    state.more ? 'text-gray-900' : 'text-gray-600'
                  }`}
                >
                  <span>More</span>
                  <svg
                    className={`ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-600 ${
                      state.more ? 'text-gray-600' : 'text-gray-400'
                    }`}
                    xmlns="https://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </nav>
            <div className="flex items-center md:ml-6 lg:ml-12">
              <div className="text-gray-400 hover:text-gray-900 outline-none focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 mr-5 hidden lg:flex font-medium">
                <a href="tel:+443330163500" className="flex space-x-2">
                  <div className="flex w-full justify-center items-center">
                    <div className="mr-2">
                      {/* @ts-ignore */}
                      <Phone width={24} className="fill-current" />
                    </div>
                    <div className="flex flex-col">
                      <span className="phone-number">0333 016 3500</span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="flex items-center space-x-6 lg:relative">
                <div className="-pt-1">
                  {/* <Link href="/booking/" passHref>
                    <a className="text-gray-400 lg:text-gray-600 hover:text-gray-900 outline-none focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 relative">
                      {totalItems > 0 && (
                        <span className="absolute top-0 left-3 h-6 w-6 rounded-full ring-2 text-white ring-white bg-brand-600 z-0 font-serif font-bold text-sm flex items-center justify-center -mt-3">
                          {totalItems}
                        </span>
                      )}
                      <Basket className="w-6 h-6" fill="currentColor" />
                    </a>
                  </Link> */}

                  <button
                    type="button"
                    onClick={() => basket.setOpen(!basket.open)}
                    className="text-gray-400 lg:text-gray-600 hover:text-gray-900 outline-none focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 relative font-medium"
                  >
                    {totalItems > 0 && (
                      <span className="absolute top-0 left-3 h-6 w-6 rounded-full ring-2 text-white ring-white bg-brand-600 z-0 font-serif font-bold text-sm flex items-center justify-center -mt-3">
                        {totalItems}
                      </span>
                    )}
                    {/* @ts-ignore */}
                    <Basket className="w-6 h-6" fill="currentColor" />
                  </button>
                </div>
                <HeaderSearch
                  show={state.search}
                  close={() => setState({ ...state, search: false })}
                />
                <button
                  type="button"
                  onClick={() => setState({ ...state, search: !state.search })}
                  className="text-gray-400 lg:text-gray-600 hover:text-gray-900 outline-none focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 font-medium"
                >
                  <svg
                    className="w-6 h-6"
                    xmlns="https://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
                <a
                  onClick={() => trackAction('phone')}
                  href="tel:+443330163500"
                  className="text-gray-400 hover:text-gray-900 outline-none focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 lg:hidden phone-number text-lg font-medium"
                >
                  <svg
                    className="w-6 h-6"
                    xmlns="https://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                </a>
                <Link
                  href={session.profile ? '/account/' : '/sign-in/'}
                  passHref
                  className="text-gray-400 hover:text-gray-900 outline-none focus:outline-none rounded-md focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 lg:hidden font-medium"
                >
                  <svg
                    className="w-6 h-6"
                    xmlns="https://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </Link>
              </div>

              {!session.profile && (
                <>
                  <Link
                    href="/sign-in/"
                    passHref
                    className="text-lg font-serif text-gray-600 hover:text-gray-900 hidden lg:block lg:ml-6 font-medium"
                  >
                    Sign in
                  </Link>
                  <Link
                    href="/sign-up/"
                    passHref
                    className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-serif text-white bg-brand-600 hover:bg-brand-700 hidden lg:block font-medium"
                  >
                    Sign up
                  </Link>
                </>
              )}
              {session.profile && (
                <Link
                  href="/account/"
                  passHref
                  className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-serif text-white bg-brand-600 hover:bg-brand-700 hidden lg:block"
                >
                  {session.profile.name.charAt(0).toUpperCase() +
                    session.profile.name.slice(1)}{' '}
                  {session.profile.surname.charAt(0).toUpperCase() +
                    session.profile.surname.slice(1)}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        id="services-menu"
        ref={servicesRef}
        className={`hidden md:block absolute z-40 inset-x-0 transform shadow-lg transition ease-out duration-200 ${
          state.services
            ? 'opacity-100 translate-y-0 pointer-events-auto'
            : 'opacity-0 -translate-y-1 pointer-events-none'
        }`}
      >
        <div className="bg-white">
          <div className="max-w-7xl mx-auto grid gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-8 xl:py-8">
            <Link
              href="/treatments/face/"
              passHref
              className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"
            >
              <div className="flex md:h-full lg:flex-col">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                    {/* @ts-ignore */}
                    <Face fill="white" className="h-6 w-6" />
                  </span>
                </div>
                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                  <div>
                    <p className="text-xl font-serif font-medium text-gray-900">
                      Face Treatments
                    </p>
                    <p className="mt-1 text-sm text-gray-600">
                      Facial skincare from true skin experts. Renew and
                      revitalise today.
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-brand-600 lg:mt-4">
                    Learn more <span aria-hidden="true">&rarr;</span>
                  </p>
                </div>
              </div>
            </Link>

            <Link
              href="/treatments/body/"
              passHref
              className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"
            >
              <div className="flex md:h-full lg:flex-col">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                    {/* @ts-ignore */}
                    <Body fill="white" className="h-6 w-6" />
                  </span>
                </div>
                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                  <div>
                    <p className="text-xl font-serif font-medium text-gray-900">
                      Body Treatments
                    </p>
                    <p className="mt-1 text-sm text-gray-600">
                      From massage, to weight loss and toning treatments we have
                      you covered
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-brand-600 lg:mt-4">
                    Learn more <span aria-hidden="true">&rarr;</span>
                  </p>
                </div>
              </div>
            </Link>

            <Link
              href="/laser-hair-removal/"
              passHref
              className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"
            >
              <div className="flex md:h-full lg:flex-col">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                    {/* @ts-ignore */}
                    <Hair fill="white" className="h-6 w-6" />
                  </span>
                </div>
                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                  <div>
                    <p className="text-xl font-serif font-medium text-gray-900">
                      Laser Hair Removal
                    </p>
                    <p className="mt-1 text-sm text-gray-600">
                      Fast, safe and effective laser hair removal for all skin
                      types
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-brand-600 lg:mt-4">
                    Learn more <span aria-hidden="true">&rarr;</span>
                  </p>
                </div>
              </div>
            </Link>

            <Link
              href="/permanent-makeup/"
              passHref
              className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"
            >
              <div className="flex md:h-full lg:flex-col">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                    {/* @ts-ignore */}
                    <Makeup fill="white" className="h-6 w-6" />
                  </span>
                </div>
                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                  <div>
                    <p className="text-xl font-serif font-medium text-gray-900">
                      Semi Permanent Makeup
                    </p>
                    <p className="mt-1 text-sm text-gray-600">
                      Define your facial features with a beautiful permanent
                      makeup crafted by our experts
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-brand-600 lg:mt-4">
                    Learn more <span aria-hidden="true">&rarr;</span>
                  </p>
                </div>
              </div>
            </Link>

            <Link
              href="/dermal-fillers/"
              passHref
              className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"
            >
              <div className="flex md:h-full lg:flex-col">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                    {/* @ts-ignore */}
                    <Lips fill="white" className="h-6 w-6" />
                  </span>
                </div>
                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                  <div>
                    <p className="text-xl font-serif font-medium text-gray-900">
                      Dermal Fillers
                    </p>
                    <p className="mt-1 text-sm text-gray-600">
                      Enhance your natural beauty with dermal fillers,
                      anti-wrinkle injections and skin boosters
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-brand-600 lg:mt-4">
                    Learn more <span aria-hidden="true">&rarr;</span>
                  </p>
                </div>
              </div>
            </Link>

            <Link
              href="/skin-boosters/"
              passHref
              className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"
            >
              <div className="flex md:h-full lg:flex-col">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                    {/* @ts-ignore */}
                    <Cleansing fill="white" className="h-6 w-6" />
                  </span>
                </div>
                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                  <div>
                    <p className="text-xl font-serif font-medium text-gray-900">
                      Skin Boosters
                    </p>
                    <p className="mt-1 text-sm text-gray-600">
                      Boost and rejuvenate with our industry leading skin
                      boosters
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-brand-600 lg:mt-4">
                    Learn more <span aria-hidden="true">&rarr;</span>
                  </p>
                </div>
              </div>
            </Link>

            <Link
              href="/anti-wrinkle-injections/"
              passHref
              className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"
            >
              <div className="flex md:h-full lg:flex-col">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                    {/* @ts-ignore */}
                    <Patient fill="white" className="h-6 w-6" />
                  </span>
                </div>
                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                  <div>
                    <p className="text-xl font-serif font-medium text-gray-900">
                      Anti Wrinkle Injections
                    </p>
                    <p className="mt-1 text-sm text-gray-600">
                      Shape, tone and contour reducing weight with our experts
                      at Soluna London
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-brand-600 lg:mt-4">
                    Learn more <span aria-hidden="true">&rarr;</span>
                  </p>
                </div>
              </div>
            </Link>

            {/* <Link
              href="/pdo-threads/"
              passHref
              className="-m-3 p-3 flex flex-col justify-between rounded-lg hover:bg-gray-50"
            >
              <div className="flex md:h-full lg:flex-col">
                <div className="flex-shrink-0">
                  <span className="inline-flex items-center justify-center h-10 w-10 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                    {/* @ts-ignore 
                    <Lift fill="white" className="h-6 w-6" />
                  </span>
                </div>
                <div className="ml-4 md:flex-1 md:flex md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                  <div>
                    <p className="text-xl font-serif font-medium text-gray-900">
                      PDO Threads
                    </p>
                    <p className="mt-1 text-sm text-gray-600">
                      Lift, tighten and rejuvenate
                    </p>
                  </div>
                  <p className="mt-2 text-sm text-brand-600 lg:mt-4">
                    Learn more <span aria-hidden="true">&rarr;</span>
                  </p>
                </div>
              </div>
            </Link> */}
          </div>
        </div>
        <div className="bg-gray-50">
          <div className="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-0 sm:px-6 lg:px-8 items-center lg:space-x-10">
            <div className="flow-root flex-1 lg:flex-none">
              <Link
                href="/treatments/"
                passHref
                className="group -m-3 p-3 flex items-center rounded-md text-base text-gray-900 hover:bg-gray-100 md:px-0 hover:text-brand-600"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-brand-600"
                  xmlns="https://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span className="ml-3">View All Treatments</span>
              </Link>
            </div>

            <div className="flow-root flex-1 lg:flex-none">
              <a
                onClick={() => trackAction('phone')}
                href="tel:+443330163500"
                className="group -m-3 p-3 flex items-center rounded-md text-base text-gray-900 hover:bg-gray-100 md:px-4 lg:p-3 phone-number hover:text-brand-600"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-brand-600"
                  xmlns="https://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span className="ml-3">Call The Team</span>
              </a>
            </div>

            <div className="flow-root flex-1 lg:flex-none">
              <Link
                href="/consultations/"
                passHref
                className="group -m-3 p-3 flex items-center rounded-md text-base text-gray-900 hover:bg-gray-100 md:px-0 hover:text-brand-600"
              >
                <Calendar
                  // @ts-ignore
                  fill="rgb(189, 189, 189)"
                  className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-brand-600"
                />
                <span className="ml-3">Book A Free Consultation</span>
              </Link>
            </div>

            <div className="flow-root flex-1 lg:flex-none">
              <Link
                href="/price-list/"
                passHref
                className="group -m-3 p-3 flex items-center rounded-md text-base text-gray-900 hover:bg-gray-100 md:px-0 hover:text-brand-600"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-brand-600"
                  xmlns="https://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span className="ml-3">Price List</span>
              </Link>
            </div>

            <div className="flex flex-row space-x-6 md:space-x-3 justify-end flex-1 lg:space-x-8">
              <a
                onClick={() => trackAction('facebook')}
                target="_blank"
                rel="noopener noreferrer"
                title="Checkout Soluna London on Facebook"
                href="https://https://www.facebook.com/solunaldn"
                className="text-gray-400 hover:text-brand-500"
              >
                <span className="sr-only">Facebook</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a
                onClick={() => trackAction('instagram')}
                target="_blank"
                rel="noopener noreferrer"
                title="Checkout Soluna London on Instagram"
                href="https://instagram.com/soluna_london"
                className="text-gray-400 hover:text-brand-500"
              >
                <span className="sr-only">Instagram</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a
                onClick={() => trackAction('youtube')}
                target="_blank"
                rel="noopener noreferrer"
                title="Checkout Soluna London on YouTube"
                href="https://www.youtube.com/channel/UCHbW58McFsG00hwVB8_JDcg"
                className="text-gray-400 hover:text-brand-500"
              >
                <span className="sr-only">YouTube</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 512 512"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path d="m224.113281 303.960938 83.273438-47.960938-83.273438-47.960938zm0 0" />
                  <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm159.960938 256.261719s0 51.917969-6.585938 76.953125c-3.691406 13.703125-14.496094 24.507812-28.199219 28.195312-25.035156 6.589844-125.175781 6.589844-125.175781 6.589844s-99.878906 0-125.175781-6.851562c-13.703125-3.6875-24.507813-14.496094-28.199219-28.199219-6.589844-24.769531-6.589844-76.949219-6.589844-76.949219s0-51.914062 6.589844-76.949219c3.6875-13.703125 14.757812-24.773437 28.199219-28.460937 25.035156-6.589844 125.175781-6.589844 125.175781-6.589844s100.140625 0 125.175781 6.851562c13.703125 3.6875 24.507813 14.496094 28.199219 28.199219 6.851562 25.035157 6.585938 77.210938 6.585938 77.210938zm0 0" />
                </svg>
              </a>
              <a
                onClick={() => trackAction('messenger')}
                target="_blank"
                rel="noopener noreferrer"
                title="Message Soluna London"
                href="https://m.me/charmellelondon"
                className="text-gray-400 hover:text-brand-500"
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path d="m12 0c-6.627 0-12 4.974-12 11.111 0 3.498 1.744 6.614 4.469 8.654v4.235l4.088-2.242c1.092.3 2.246.464 3.443.464 6.627 0 12-4.975 12-11.11s-5.373-11.112-12-11.112zm1.191 14.963-3.055-3.26-5.963 3.26 6.559-6.963 3.131 3.259 5.889-3.259z" />
                </svg>
              </a>
              <a
                onClick={() => trackAction('whatsapp')}
                target="_blank"
                rel="noopener noreferrer"
                title="Message Soluna London on WhatsApp"
                href="https://wa.me/message/UI5WMKIS6WJDP1"
                className="text-gray-400 hover:text-brand-500"
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path d="m17.498 14.382c-.301-.15-1.767-.867-2.04-.966-.273-.101-.473-.15-.673.15-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.174-.3-.019-.465.13-.615.136-.135.301-.345.451-.523.146-.181.194-.301.297-.496.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.172-.015-.371-.015-.571-.015s-.523.074-.797.359c-.273.3-1.045 1.02-1.045 2.475s1.07 2.865 1.219 3.075c.149.195 2.105 3.195 5.1 4.485.714.3 1.27.48 1.704.629.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345m-5.446 7.443h-.016c-1.77 0-3.524-.48-5.055-1.38l-.36-.214-3.75.975 1.005-3.645-.239-.375c-.99-1.576-1.516-3.391-1.516-5.26 0-5.445 4.455-9.885 9.942-9.885 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99-.004 5.444-4.46 9.885-9.935 9.885m8.453-18.302c-2.28-2.204-5.28-3.449-8.475-3.449-6.582 0-11.941 5.334-11.944 11.893 0 2.096.549 4.14 1.595 5.945l-1.696 6.162 6.335-1.652c1.746.943 3.71 1.444 5.71 1.447h.006c6.585 0 11.946-5.336 11.949-11.896 0-3.176-1.24-6.165-3.495-8.411" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        id="more-menu"
        ref={moreRef}
        className={`hidden md:block absolute z-40 inset-x-0 transform transition ease-out duration-200 shadow-lg ${
          state.more
            ? 'opacity-100 translate-y-0 pointer-events-auto'
            : 'opacity-0 -translate-y-1 pointer-events-none'
        }`}
      >
        <div className="absolute inset-0 flex">
          <div className="bg-white w-1/2"></div>
          <div className="bg-gray-50 w-1/2"></div>
        </div>
        <div className="relative max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2">
          <nav className="grid gap-y-10 px-4 py-8 bg-white sm:grid-cols-2 sm:gap-x-8 sm:py-12 sm:px-6 lg:px-8 xl:pr-12">
            <div>
              <h3 className="text-sm font-medium tracking-wide text-gray-600 uppercase">
                Company
              </h3>
              <ul className="mt-5 space-y-6">
                {about.map((link, idx) => (
                  <li className="flow-root" key={`more-about-${idx}`}>
                    <Link
                      href={link.href}
                      passHref
                      className="-m-3 p-3 flex items-center rounded-md text-base text-gray-900 hover:bg-gray-50"
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="text-sm font-medium tracking-wide text-gray-600 uppercase">
                Legal
              </h3>
              <ul className="mt-5 space-y-6">
                {legal.map((link, idx) => (
                  <li className="flow-root" key={`more-legal-${idx}`}>
                    <Link
                      href={link.href}
                      passHref
                      className="-m-3 p-3 flex items-center rounded-md text-base text-gray-900 hover:bg-gray-50"
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
          <div className="bg-gray-50 px-4 py-8 sm:py-12 sm:px-6 lg:px-8 xl:pl-12">
            <div>
              <h3 className="text-sm font-medium tracking-wide text-gray-600 uppercase">
                From the blog
              </h3>
              <ul className="mt-6 space-y-6">
                <li className="flow-root">
                  <Link
                    href="/articles/unwanted-fat-or-cellulite/"
                    passHref
                    className="-m-3 p-3 flex rounded-lg hover:bg-gray-100"
                  >
                    <div className="hidden sm:block flex-shrink-0">
                      <Image
                        src="/articles/cellulite.jpg"
                        alt="What causes unwanted clusters of cellulite to show
                        through the skin? Is there any way to get rid of
                        unwanted fat once and for all? Learn more today with
                        Soluna London"
                        className="w-32 h-20 object-cover rounded-md"
                        width={128}
                        height={80}
                      />
                    </div>
                    <div className="w-0 flex-1 sm:ml-8">
                      <h4 className="text-base text-gray-900 truncate">
                        Unwanted Fat or Cellulite
                      </h4>
                      <p className="mt-1 text-sm text-gray-600">
                        What causes unwanted clusters of cellulite to show
                        through the skin? Is there any way to get rid of
                        unwanted fat once and for all? Learn more today with
                        Soluna London
                      </p>
                    </div>
                  </Link>
                </li>
                <li className="flow-root">
                  <Link
                    href="/articles/laser-hair-removal-for-dark-skin/"
                    passHref
                    className="-m-3 p-3 flex rounded-lg hover:bg-gray-100"
                  >
                    <div className="hidden sm:block flex-shrink-0">
                      <Image
                        src="/articles/laser-hair-removal-for-dark-skin-tones.jpg"
                        className="w-32 h-20 object-cover rounded-md"
                        alt="Learn more about the pros, cons and safety of laser
                        hair removal for dark skin with our latest information
                        and technology at Soluna London"
                        width={128}
                        height={80}
                      />
                    </div>
                    <div className="w-0 flex-1 sm:ml-8">
                      <h4 className="text-base text-gray-900 truncate">
                        Laser Hair Removal for Dark Skin
                      </h4>
                      <p className="mt-1 text-sm text-gray-600">
                        Learn more about the pros, cons and safety of laser hair
                        removal for dark skin with our latest information and
                        technology at Soluna London
                      </p>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-6 text-sm">
              <Link
                href="/articles/"
                passHref
                className="text-brand-600 hover:text-brand-500"
              >
                {' '}
                View all posts<span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 relative">
          <div className="max-w-7xl mx-auto space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-0 sm:px-6 lg:px-8 items-center lg:space-x-10">
            <div className="flow-root flex-1 lg:flex-none">
              <Link
                href="/treatments/"
                passHref
                className="-m-3 p-3 flex items-center rounded-md text-base text-gray-900 hover:bg-gray-100"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-gray-400"
                  xmlns="https://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span className="ml-3">View All Treatments</span>
              </Link>
            </div>

            <div className="flow-root flex-1 lg:flex-none">
              <a
                onClick={() => trackAction('phone')}
                href="tel:+443330163500"
                className="-m-3 p-3 flex items-center rounded-md text-base text-gray-900 hover:bg-gray-100 md:px-6 lg:p-3 phone-number"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-gray-400"
                  xmlns="https://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span className="ml-3">Call The Team</span>
              </a>
            </div>

            <div className="flow-root flex-1 lg:flex-none">
              <Link
                href="/consultations/"
                passHref
                className="-m-3 p-3 flex items-center rounded-md text-base text-gray-900 hover:bg-gray-100"
              >
                <Calendar
                  // @ts-ignore
                  fill="rgb(189, 189, 189)"
                  className="flex-shrink-0 h-6 w-6 text-gray-400"
                />
                <span className="ml-3">Book A Free Consultation</span>
              </Link>
            </div>

            <div className="flow-root flex-1 lg:flex-none">
              <Link
                href="/price-list/"
                passHref
                className="group -m-3 p-3 flex items-center rounded-md text-base text-gray-900 hover:bg-gray-100 md:px-0 hover:text-brand-600"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-brand-600"
                  xmlns="https://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span className="ml-3">Price List</span>
              </Link>
            </div>

            <div className="flex flex-row space-x-6 md:space-x-3 justify-end flex-1 lg:space-x-8">
              <a
                onClick={() => trackAction('facebook')}
                target="_blank"
                rel="noopener noreferrer"
                title="Checkout Soluna London on Facebook"
                href="https://https://www.facebook.com/solunaldn"
                className="text-gray-400 hover:text-brand-500"
              >
                <span className="sr-only">Facebook</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a
                onClick={() => trackAction('instagram')}
                target="_blank"
                rel="noopener noreferrer"
                title="Checkout Soluna London on Instagram"
                href="https://instagram.com/soluna_london"
                className="text-gray-400 hover:text-brand-500"
              >
                <span className="sr-only">Instagram</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a
                onClick={() => trackAction('youtube')}
                target="_blank"
                rel="noopener noreferrer"
                title="Checkout Soluna London on YouTube"
                href="https://www.youtube.com/channel/UCHbW58McFsG00hwVB8_JDcg"
                className="text-gray-400 hover:text-brand-500"
              >
                <span className="sr-only">YouTube</span>
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 512 512"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path d="m224.113281 303.960938 83.273438-47.960938-83.273438-47.960938zm0 0" />
                  <path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm159.960938 256.261719s0 51.917969-6.585938 76.953125c-3.691406 13.703125-14.496094 24.507812-28.199219 28.195312-25.035156 6.589844-125.175781 6.589844-125.175781 6.589844s-99.878906 0-125.175781-6.851562c-13.703125-3.6875-24.507813-14.496094-28.199219-28.199219-6.589844-24.769531-6.589844-76.949219-6.589844-76.949219s0-51.914062 6.589844-76.949219c3.6875-13.703125 14.757812-24.773437 28.199219-28.460937 25.035156-6.589844 125.175781-6.589844 125.175781-6.589844s100.140625 0 125.175781 6.851562c13.703125 3.6875 24.507813 14.496094 28.199219 28.199219 6.851562 25.035157 6.585938 77.210938 6.585938 77.210938zm0 0" />
                </svg>
              </a>
              <a
                onClick={() => trackAction('messenger')}
                target="_blank"
                rel="noopener noreferrer"
                title="Message Soluna London"
                href="https://m.me/charmellelondon"
                className="text-gray-400 hover:text-brand-500"
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path d="m12 0c-6.627 0-12 4.974-12 11.111 0 3.498 1.744 6.614 4.469 8.654v4.235l4.088-2.242c1.092.3 2.246.464 3.443.464 6.627 0 12-4.975 12-11.11s-5.373-11.112-12-11.112zm1.191 14.963-3.055-3.26-5.963 3.26 6.559-6.963 3.131 3.259 5.889-3.259z" />
                </svg>
              </a>
              <a
                onClick={() => trackAction('whatsapp')}
                target="_blank"
                title="Message Soluna London on WhatsApp"
                rel="noopener noreferrer"
                href="https://wa.me/message/UI5WMKIS6WJDP1"
                className="text-gray-400 hover:text-brand-500"
              >
                <svg
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path d="m17.498 14.382c-.301-.15-1.767-.867-2.04-.966-.273-.101-.473-.15-.673.15-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.174-.3-.019-.465.13-.615.136-.135.301-.345.451-.523.146-.181.194-.301.297-.496.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.172-.015-.371-.015-.571-.015s-.523.074-.797.359c-.273.3-1.045 1.02-1.045 2.475s1.07 2.865 1.219 3.075c.149.195 2.105 3.195 5.1 4.485.714.3 1.27.48 1.704.629.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345m-5.446 7.443h-.016c-1.77 0-3.524-.48-5.055-1.38l-.36-.214-3.75.975 1.005-3.645-.239-.375c-.99-1.576-1.516-3.391-1.516-5.26 0-5.445 4.455-9.885 9.942-9.885 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99-.004 5.444-4.46 9.885-9.935 9.885m8.453-18.302c-2.28-2.204-5.28-3.449-8.475-3.449-6.582 0-11.941 5.334-11.944 11.893 0 2.096.549 4.14 1.595 5.945l-1.696 6.162 6.335-1.652c1.746.943 3.71 1.444 5.71 1.447h.006c6.585 0 11.946-5.336 11.949-11.896 0-3.176-1.24-6.165-3.495-8.411" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        ref={mobileRef}
        className={`absolute z-40 top-0 inset-x-0 p-2 duration-200 ease-out transition transform origin-top-right md:hidden ${
          state.menu
            ? 'opacity-100 scale-100 pointer-events-auto'
            : 'opacity-0 scale-95 pointer-events-none'
        }`}
        style={{ zIndex: 9999 }}
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-3 px-5 sm:pb-8">
            <div className="flex items-center justify-between">
              <div>{/* <CLLogo className="h-14 w-auto" /> */}</div>
              <div className="-mr-2">
                <button
                  onClick={() =>
                    setState({
                      more: null,
                      services: null,
                      menu: null,
                      search: false,
                    })
                  }
                  type="button"
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500"
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="https://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="-mt-6 sm:mt-8">
              <nav>
                <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                  <Link
                    href="/treatments/face/"
                    passHref
                    className="-m-3 flex items-center px-3 py-1.5 sm:p-3 rounded-lg hover:bg-gray-50"
                  >
                    <div className="flex-shrink-0 flex items-center justify-center h-9 w-9 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                      {/* @ts-ignore */}
                      <Face fill="white" className="h-6 w-6 text-white" />
                    </div>
                    <div className="ml-4 text-base text-gray-900">
                      Face Treatments
                    </div>
                  </Link>

                  <Link
                    href="/treatments/body/"
                    passHref
                    className="-m-3 flex items-center px-3 py-1.5 sm:p-3 rounded-lg hover:bg-gray-50"
                  >
                    <div className="flex-shrink-0 flex items-center justify-center h-9 w-9 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                      {/* @ts-ignore */}
                      <Body fill="white" className="h-6 w-6 text-white" />
                    </div>
                    <div className="ml-4 text-base text-gray-900">
                      Body Treatments
                    </div>
                  </Link>

                  <Link
                    href="/laser-hair-removal/"
                    passHref
                    className="-m-3 flex items-center px-3 py-1.5 sm:p-3 rounded-lg hover:bg-gray-50"
                  >
                    <div className="flex-shrink-0 flex items-center justify-center h-9 w-9 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                      {/* @ts-ignore */}
                      <Hair fill="white" className="h-6 w-6 text-white" />
                    </div>
                    <div className="ml-4 text-base text-gray-900">
                      Laser Hair Removal
                    </div>
                  </Link>

                  <Link
                    href="/dermal-fillers/"
                    passHref
                    className="-m-3 flex items-center px-3 py-1.5 sm:p-3 rounded-lg hover:bg-gray-50"
                  >
                    <div className="flex-shrink-0 flex items-center justify-center h-9 w-9 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                      {/* @ts-ignore */}
                      <Lips fill="white" className="h-6 w-6 text-white" />
                    </div>
                    <div className="ml-4 text-base text-gray-900">
                      Dermal Fillers
                    </div>
                  </Link>

                  <Link
                    href="/skin-boosters/"
                    passHref
                    className="-m-3 flex items-center px-3 py-1.5 sm:p-3 rounded-lg hover:bg-gray-50"
                  >
                    <div className="flex-shrink-0 flex items-center justify-center h-9 w-9 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                      <Cleansing
                        // @ts-ignore
                        fill="white"
                        className="h-6 w-6 text-white currentColor"
                      />
                    </div>
                    <div className="ml-4 text-base text-gray-900">
                      Skin Boosters
                    </div>
                  </Link>

                  <Link
                    href="/anti-wrinkle-injections/"
                    passHref
                    className="-m-3 flex items-center px-3 py-1.5 sm:p-3 rounded-lg hover:bg-gray-50"
                  >
                    <div className="flex-shrink-0 flex items-center justify-center h-9 w-9 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                      <Patient
                        // @ts-ignore
                        fill="white"
                        className="h-6 w-6 text-white currentColor"
                      />
                    </div>
                    <div className="ml-4 text-base text-gray-900">
                      Anti Wrinkle Injections
                    </div>
                  </Link>

                  {/* <Link
                    href="/pdo-threads/"
                    passHref
                    className="-m-3 flex items-center px-3 py-1.5 sm:p-3 rounded-lg hover:bg-gray-50"
                  >
                    <div className="flex-shrink-0 flex items-center justify-center h-9 w-9 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                      <Lift
                        // @ts-ignore
                        fill="white"
                        className="h-6 w-6 text-white currentColor"
                      />
                    </div>
                    <div className="ml-4 text-base text-gray-900">
                      PDO Threads
                    </div>
                  </Link> */}

                  <Link
                    href="/permanent-makeup/"
                    passHref
                    className="-m-3 flex items-center px-3 py-1.5 sm:p-3 rounded-lg hover:bg-gray-50"
                  >
                    <div className="flex-shrink-0 flex items-center justify-center h-9 w-9 rounded-md bg-brand-500 text-white sm:h-12 sm:w-12">
                      <Makeup
                        // @ts-ignore
                        fill="white"
                        className="h-6 w-6 text-white currentColor"
                      />
                    </div>
                    <div className="ml-4 text-base text-gray-900">
                      Semi Permanent Makeup
                    </div>
                  </Link>
                </div>

                <div className="mt-8 text-base">
                  <Link
                    href="/laser-hair-removal/"
                    passHref
                    className="text-brand-600 hover:text-brand-500"
                  >
                    {' '}
                    Laser Hair Removal<span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
                <div className="mt-3 text-base">
                  <Link
                    href="/treatments/"
                    passHref
                    className="text-brand-600 hover:text-brand-500"
                  >
                    {' '}
                    View all treatments<span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
                <div className="mt-3 text-base">
                  <Link
                    href="/packages/"
                    passHref
                    className="text-brand-600 hover:text-brand-500"
                  >
                    {' '}
                    View our latest packages{' '}
                    <span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
                <div className="mt-3 text-base">
                  <Link
                    href="/price-list/"
                    passHref
                    className="text-brand-600 hover:text-brand-500"
                  >
                    {' '}
                    View price list<span aria-hidden="true">&rarr;</span>
                  </Link>
                </div>
              </nav>
            </div>
          </div>
          <div className="p-3">
            <div className="grid grid-cols-2 gap-3">
              <Link
                href="/packages/"
                passHref
                className="rounded-md text-base text-gray-900 hover:text-gray-700"
              >
                Packages
              </Link>
              {/* <Link
                href="/products"
                passHref
                className="rounded-md text-base text-gray-900 hover:text-gray-700"
              >
                Skincare products
              </Link> */}
              <Link
                href="/conditions"
                passHref
                className="rounded-md text-base text-gray-900 hover:text-gray-700"
              >
                Conditions
              </Link>
              {about.map((link, idx) => (
                <Link
                  key={`header-about-${idx}`}
                  href={link.href}
                  passHref
                  className="rounded-md text-base text-gray-900 hover:text-gray-700"
                >
                  {link.text}
                </Link>
              ))}
            </div>
            <div className="mt-6">
              <a
                onClick={() => trackAction('phone')}
                href="tel:+443330163500"
                className="btn-primary mb-2"
                id="call-now-button-header"
              >
                <div className="flex w-full justify-center items-center">
                  <div className="mr-2">
                    {/* @ts-ignore */}
                    <Phone width={24} className="fill-current" />
                  </div>
                  <div className="flex flex-col">
                    <span className="phone-number">0333 016 3500</span>
                  </div>
                </div>
              </a>
              {!session.profile && (
                <>
                  <Link
                    href="/sign-up/"
                    passHref
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-white bg-brand-600 hover:bg-brand-700"
                  >
                    Sign up
                  </Link>
                  <p className="mt-6 text-center text-base text-gray-600">
                    Existing client?{' '}
                    <Link
                      href="/sign-in/"
                      passHref
                      className="text-brand-600 hover:text-brand-500"
                    >
                      Sign in
                    </Link>
                  </p>
                </>
              )}
              {session.profile && (
                <Link
                  href="/account/"
                  passHref
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base text-white bg-brand-600 hover:bg-brand-700"
                >
                  Your Account
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
